body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navigation-bar {
  gap: 10px;
  background-color: #582E16;
  padding: 5px 0 0 0;
}

.navigation-item {
  padding: 10px;
  background-color: #ffea00;
  color: black;
  border-radius: 10px 10px 0 0px;
}

.link {
  text-decoration: none;
  font-weight: bold;
}

.navbar-toggler {
  background-color: #ffea00;
}

.body-content {
  min-height: 80vh;
}

.bg-wood {
  background-image: url('./_assets/bgWood.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
}

.transparent-background {
  position: relative;
  z-index: 0;
}

.transparent-background::before {
  content: "";
  position: absolute;
  inset: 0;
  background-color: rgba(88, 46, 22, 0.9);
  z-index: -1;
}

.pages-content {
  min-height: 70vh;
  color: #ffffff;
  line-height: 35px;
  text-align: justify;
}

.nota-rodape {
  width: 100%;
  color: #ffffff;
}

.regua-logos {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
  padding: 5px;
  background-color: #582e16;
}

.regua-logos img {
  height: 70px;

}

.card-obra {
  color: #ffffff;
  background-color: #A16644;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 10px;
  transition: .5s ease-in-out;
}

.card-obra:hover {
  transform: scale(1.1);
}

.card-description {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.card-info {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 2;
}

.container-alfabeto {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50px, 1fr));
  gap: 15px;
  padding: 10px;
}